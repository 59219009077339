<template>
  <head>
    <title>Stanlakeside</title>
  </head>
  <div id="app">
    <countTimer />
  </div>
</template>

<script>
import countTimer from "./components/countTimer.vue";

export default {
  components: {
    countTimer
  },
  name: 'HomePage',
  metaInfo: {
    title: 'Stanlakeside',
    meta: [
      {
        name: 'description',
        content: 'Najlepszy serwer Arma w Polsce.'
      }
    ]
  }
};
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@200;300;400;500;600;700;800&display=swap');

* {
  margin: 0;padding: 0;
  box-sizing: border-box;
  user-select: none;
  overflow: hidden;

  color: white;

  --body-font: "DM Sans", Helvetica, Arial, sans-serif;
  --title-font: "DM Sans", Helvetica, Arial, sans-serif;
}

#app { display: block; }
</style>
